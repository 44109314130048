
import React from 'react';
import logo from '../../assets/imgs/logo_bluelry_transparent.png';

const Logo = () => {
  return (
    <img src={logo} alt='logo_bluelry' style={{ width: '11rem'}} />
  );
};

export default Logo;
